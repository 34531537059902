<template>
  <div class="login">
    <div class="form" ref="form">
      <h2>后台管理系统</h2>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item prop="userName" class="form-item">
          <el-input
            type="userName"
            v-model="ruleForm.userName"
            placeholder="账户"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" class="form-item">
          <el-input
            type="password"
            v-model="ruleForm.password"
            show-password
            placeholder="密码"
            @keyup.enter.native="submitForm('ruleForm')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="primary"
            @click="submitForm('ruleForm')"
            >登 录</el-button
          >
        </el-form-item>
      </el-form>
      <div class="forgot">
        <!-- <router-link :to="{path:'/forgetPassword'}">忘记密码</router-link> -->
      </div>
    </div>

    <!-- <router-link :to="{path:'/MNWC/homePage'}">登录</router-link> -->
  </div>
</template>

<script>
import { login } from "../apis/users";

export default {
  name: "login", //登录
  data() {
    return {
      ruleForm: {
        userName: "",
        password: "",
      },
      rules: {
        userName: [{ required: true, message: "请输入账户", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      client: document.documentElement.clientWidth,
    };
  },
  mounted() {
    this.init(this.client);
    window.onresize = () => {
      // 定义窗口大小变更通知事件
      const client = document.documentElement.clientWidth; //窗口宽度
      this.init(client);
    };
    // *进入就要删除token
    localStorage.removeItem("token");
    // let account = localStorage.getItem("save-account");
    // if (account) {
    //   account = decodeURIComponent(account);
    //   this.ruleForm.userName = JSON.parse(account).userName;
    //   this.ruleForm.password = JSON.parse(account).password;
    // }
  },
  methods: {
    init(client) {
      const flag = ((client - 24 * 23) / 24) * 4 + 24 * 3;
      if (flag < 50) return;
      if (this.$refs.form) this.$refs.form.style.right = flag + "px";
    },
    //登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.clickLogin();
        } else {
          return false;
        }
      });
    },
    clickLogin() {
      //调用接口函数
      login(this.ruleForm).then((res) => {
        if (res.data.code == 0) {
          //把用户角色存储在本地存储
          // localStorage.role = res.data.role;  //权限
          localStorage.acc = res.data.data.userName; //用户名
          localStorage.id = res.data.data.id; //用户id
          // localStorage.token = res.data.token //用户的token
          localStorage.token = res.data.data.token; //*用户的token
          // *把token穿给vuex库里
          this.$store.commit("setToken", res.data.data.token);
          this.$router.push("/index/bannerlist"); //让路由对象跳转到指定hash地址

          this.$message({
            message: "系统提示，登录成功！",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background-image: url("../assets/imgs/banner.jpg");
  background-size: cover;
  position: relative;
  box-sizing: border-box;
  .form {
    position: absolute;
    top: 45%;
    right: 220px;
    width: 550px;
    left: 70%;
    h2 {
      color: #ffffff;
    }
    transform: translate(-50%, -50%);
    .title {
      width: 314px;
      height: 54px;
    }
    .demo-ruleForm {
      margin-top: 36px;
    }
    ::v-deep .el-input__inner {
      background-color: transparent;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #e9e9e9;
      line-height: 18px;
      border: none;
      padding: 0;
      &:-webkit-autofill {
        /* 字体颜色，需要设置，否则会变成黑色 */
        -webkit-text-fill-color: #e9e9e9 !important;
        /* 变成透明背景色的固定写法，只针对表单自动填充的背景色 */
        transition: background-color 5000s ease-in-out 0s;
        caret-color: #e9e9e9;
      }
    }
    .el-input {
      border-bottom: 1px solid #81878e;
      width: 94%;
    }
    .form-item {
      // border-bottom: 1px solid #81878e;
      padding-bottom: 10px;
    }
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
    }
    .primary {
      width: 130px;
      margin-top: 10px;
    }
  }
  .forgot {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 14px;
    cursor: pointer;
  }
}
</style>
