// 存放所有用户相关的接口函数
// import axios from "../utils/utils";
import axios from "../utils/request";
//* 方法一
// export const login = params => {
//     return axios({
//       url: `user/login`,
//       data: params,
//       method: "post"
//     });
//   };
//* 用户登录
// 这里需要把axios返回出去,方便在对应组建中.then接响应
export let login = (params) => axios.post("user/login ", params);

//* 用户登出
export let logout = () => axios.post("user/loginOut");

//* 用户列表
export let getUserList = (params) => axios.post("user/getUsers", params);

// *修改用户密码
export let updatePassword = (params) => axios.post("user/changPWD", params);

// *删除用户
export let deleteUser = (params) => axios.post("user/removeUser", params);

// *添加用户
export let addUser = (params) => axios.post("user/addUser", params);
