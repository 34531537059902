import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'  //引入登录
// import { checktoken } from '../apis/users'


Vue.use(VueRouter)

const routes = [
  //除了默认页面（登录），其他页面都需要懒加载！！提升首屏访问性能
  {
    path: '/',
    name: 'login', //此路由对象的id，不可重复
    component: Login
  },
  {
    path: '/index',
    name: 'index',
    //如果想要懒加载一个组件，不需要直接import，
    //在component不要直接书写组件！书写一个() => import('要加载的组件')
    component: () => import('../views/Index'),

    //嵌套二级路由
    children: [
    // { path: '/index/main', name: 'index/main', component: () => import('../views/modules/Main') }, //主页-首页

    //用户管理二级路由
    { path: '/index/userlist', name: 'index/userlist', component: () => import('../views/modules/users/UserList') }, //主页-用户列表
    // { path: '/index/changepwd', name: 'index/changepwd', component: () => import('../views/modules/users/ChangePwd') }, //主页-修改密码
    { path: '/index/useradd', name: 'index/useradd', component: () => import('../views/modules/users/UserAdd') }, //主页-添加用户
    { path: '/index/userinfo', name: 'index/userinfo', component: () => import('../views/modules/users/UserInfo') }, //主页-用户信息
    
    //*新闻管理二级路由
    { path: '/index/newslist', name: 'index/newslist', component: () => import('../views/modules/news/NewList') }, //主页-新闻列表
    { path: '/index/newsadd', name: 'index/newsadd', component: () => import('../views/modules/news/NewAdd') }, //主页-添加新闻

    //*案例管理二级路由
      { path: '/index/projectlist', name: 'index/projectlist', component: () => import('../views/modules/projects/ProjectList') }, //主页-案例列表
      { path: '/index/projectadd', name: 'index/projectadd', component: () => import('../views/modules/projects/ProjectAdd') }, //主页-添加案例

    // *首页banner管理二级路由
    { path: '/index/bannerlist', name: 'index/bannerlist', component: () => import('../views/modules/bannerImg/BannerList') }, //主页-banner列表

    // *招聘管理二级路由
    { path: '/index/joblist', name: 'index/joblist', component: () => import('../views/modules/joinUs/JobList') }, //主页-招聘列表
    ]
  },
  {
    path: '*',  //* 表示其他所有hash地址
    name: '404',
    component: () => import('../views/Error404')
  }
]

const router = new VueRouter({
  routes
})

// 全局前置 beforeEach   
router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    //用户前往登录页面，直接放行不用拦截！！
    next()
  } else {
    if (localStorage.token) {
      next()
    } else {
      next('/') 
    }
    // checktoken({ token: localStorage.token }).then(res => {
    //   if (res.data.code == 0) {
    //     //token有效，还在有效期
    //     next()
    //   } else {
    //     //token失效，不能强行闯入系统！！！需要拦截，必须要登录
    //     next('/')   //传入/，让next前往/（登录页面）
    //   }
    // })
  }
})

export default router
