import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
// *应用vuex组件
import store from './store'

//所有公共样式都在main.js中引入
import 'element-ui/lib/theme-chalk/index.css'
import './assets/styles/common.less'


Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
