// 工具函数： 可以放通用的变量||函数
// 存放所有用户相关的接口函数
import axios from "axios";
import Vue from "vue";

// 设置请求基本服务器路径
// axios.defaults.baseURL = "http://106.15.251.172:8888/";
// axios.defaults.baseURL = "http://192.168.22.127:8080/";

//*全局路径
export let apiAll = "https://api.spreaderone.com/"; // *线上
// export let apiAll = "http://106.15.251.172:8888/"; // *预生产
// export let apiAll ="http://192.168.22.127:8080/" // *本地

// 产生一个bus用来跨级传值
export let bus = new Vue();

// 获取 yyyy-mm-dd 格式数据
export let getYYYYMMDD = (datestr) => {
  let time = new Date(datestr);
  return (
    time.getFullYear() +
    "-" +
    fillzero(time.getMonth() + 1) +
    "-" +
    fillzero(time.getDate())
  );
};

// 补0
export let fillzero = (num) => (num < 10 ? "0" + num : num);

export default axios;
