import axios from "axios";
// *引入全局路径
import { apiAll } from "./utils";
// *引入vuex
import store from "../store";

// *引入element-ui的message组件
import { Message } from "element-ui";
// import global from "global";
// import store from "@/store";
import router from "@/router";

/**
 * 可以导出多个实例满足不同的需求
 */
// 创建axios实例

const instance = axios.create({
  baseURL: apiAll,
  timeout: 300000,
});

// request-interceptor
instance.interceptors.request.use(
  (config) => {
    // config.headers["Content-Type"] = "multipart/form-data";
    const token = localStorage.getItem("token");
    // *本地有token，则携带token，没有用从vuex的store中获取
    config.headers["token"] = token || store.state.token;
    // config.headers.Authorization = `Bearer ${token}`;
    // if (config.url.indexOf("sellapi") !== -1) {
    //   config.headers["X-Store-Id"] = store.getters.storeId || "";
    // }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    //*code为200时,全局提示请返回登录页面重新登录
    if (response.data.code === 200) {
      Message({
        message: "请重新登录",
        type: "error",
      });
      //*跳转到登录页面
      router.push("/");
      // *删除token和本地存储的token
      store.commit("setToken", "");
      localStorage.removeItem("token");
    }
    if (response.status === 204) {
      response.data = null;
    }
    return response;
  },
  (error) => {
    const err = error.response;
    // console.log("异常信息", err);
    if (err.status === 500) {
      Message({
        message: "服务器异常",
        type: "error",
      });
    }

    // return Promise.reject(err);
  }
);

export default instance;
